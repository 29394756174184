// import { createRouter, createWebHashHistory } from "vue-router";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: "active",
  // mode: "history",
  routes: [
    {
      path: "/404",
      component: () => import("@/views/404"),
      hidden: true,
    },
    {
      path: "/",
      name: "index",
      component: () => import("@/views/index"),
      hidden: true,
    },
    {
      path: "/about",
      name: "about",
      component: () => import("@/views/about"),
      hidden: true,
    },

    // 404 page must be placed at the end !!!
    // { path: "/:pathMatch(.*)*", redirect: "/404", hidden: true },
  ],
});
const prefix = "/";
// const router = createRouter({
//   // 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//   history: createWebHashHistory(),
//   base: prefix,
//   routes: '',
// });

export default router;
