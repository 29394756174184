<template>
  <header id="app-gym-header" class="header-container" style="background: #fff">
    <div class="header">
      <div class="logo-container" @click="$router.push('/')">
        <a href="#">
          <img :src="logoPic" alt="" />
          <span class="logo-tit">{{ beian.name }}</span>
        </a>
      </div>
      <div class="navbar">
        <ul class=" ">
          <li class="navbar-item" v-for="(item, index) in tabList" :key="index">
            <router-link
              :to="item.url"
              :style="nowPath == item.url ? 'color:#275aff' : ''"
            >
              {{ item.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import $ from "jquery";
// import checkHost from "../../public/host";
export default {
  name: "Header",
  data() {
    // 参数
    return {
      nowPath: "/",
      tabList: [
        { name: "首页", url: "/" },
        { name: "关于我们", url: "/about" },
      ],
      logoPic: "http://tuielf.cn/22.png",
      beian: {},
    };
  },
  watch: {
    $route: {
      handler: function (val) {
        var that = this;
        that.nowPath = val.fullPath;
        window.scrollTo(0, 0);
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    // const beian = checkHost(window.location.host);
    const beian = localStorage.getItem("beian");
    this.beian = JSON.parse(beian);
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.header-container {
  position: sticky;
  top: 0;
  height: 76px;
  box-shadow: 0 2px 10px 0 rgba(51, 51, 51, 0.1);
  z-index: 99;

  .header {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-items: center;

    .logo-container {
      height: 76px;

      a {
        display: flex;
        align-items: center;
        height: 100%;
      }
      img {
        height: 100%;
      }

      .logo-tit {
        font-size: 28px;
        font-weight: 800;
      }
    }

    .navbar {
      flex: 1;
      height: 76px;

      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        .navbar-item {
          display: flex;
          align-items: center;
          margin-right: 76px;
          height: 100%;

          &:hover {
            a {
              color: #275aff;
            }
          }
        }
      }
    }
  }
}
</style>
