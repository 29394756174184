<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Vue from "vue";
import Footer from "./components/Footer";
import Header from "./components/Header";
// import checkHost from "../public/host";
export default {
  name: "App",
  components: {
    Footer,
    Header,
  },

  mounted() {
    // const beian = checkHost(window.location.host);
    const beian = localStorage.getItem("beian");
    document.title = JSON.parse(beian).title;
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  font-family: Microsoft YaHei, Nespresso Lucas, Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
}

body,
html {
  // overflow-x: hidden;
  min-width: 1200px;
}

a {
  color: #252525;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
}
img {
  vertical-align: top;
}

ul,
li {
  list-style: none;
}

.w {
  width: 1200px;
  margin: 0 auto;
}

.w1 {
  width: 1600px;
  margin: 0 auto;
}

.breadcrumb {
  padding: 0 150px;
  font-size: 18px;
  box-sizing: border-box;

  span {
    color: #252525;
    font-weight: 500;
    font-size: 20px;
  }
}

.nav-tab,
.nav-tab1 {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  padding: 0 100px;
  height: 60px;
  background-color: rgba(23, 23, 23, 0.8);
  z-index: 999;
  display: none;

  .active {
    font-weight: 500;
    color: #fff !important;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .tab-item {
    margin-right: 200px;
    font-size: 18px;
    color: #949494;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      color: #fff;
    }

    // a.router-link-exact-active {
    //   background-color: orange;
    //   color: #fff;
    // }
  }
}

.section-bg {
  width: 100%;
}
</style>
