<template>
  <footer class="footer_section">
    <div class="internet-content-provider">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        {{ beian.num }}
      </a>
      {{ beian.name }}
    </div>
  </footer>
</template>

<script>
// import checkHost from "../../public/host";
export default {
  name: "Footer",
  data() {
    // 参数
    return {
      beian: {},
    };
  },
  created() {
    // const beian = checkHost("86ln.com");
    // const beian = checkHost(window.location.host);
    const beian = localStorage.getItem("beian");
    this.beian = JSON.parse(beian);
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.footer_section {
  color: #fff;
  background-color: #275aff;

  .internet-content-provider {
    padding: 20px;
    text-align: center;

    a {
      color: #fff;
      font-size: 18px;
    }
  }
}
</style>
